try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.moment = require('moment');
    window.flatpickr = require('flatpickr');
    window.select2 = require('select2');

    window.bootstrap = require('bootstrap');
    require('./create_order');
} catch (e) {
    console.log(e);
}

import {CountUp} from "countup.js";

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

$(function() {
    flatpickr(".js-datetimepicker", {
        enableTime: true,
        altInput: true,
        altFormat: "F j, Y H:i",
        dateFormat: "Y-m-d H:i",
        minuteIncrement: 15,
    });

    $('.select').select2({
        theme: 'bootstrap4',
    });

    let csrf = document.head.querySelector('meta[name="csrf-token"]');

    let laravel = {
        initialize: function() {
            this.registerEvents();
        },

        registerEvents: function() {
            $('body').on('click', 'a[data-method]', this.handleMethod);
        },

        handleMethod: function(e) {
            let link = $(this);
            let httpMethod = link.data('method');
            let form;

            // If the data-method attribute is not PUT or DELETE,
            // then we don't know what to do. Just ignore.
            if ( $.inArray(httpMethod, ['put', 'delete', 'PUT', 'DELETE']) === - 1 ) {
                return;
            }

            // Allow user to optionally provide data-confirm="Are you sure?"
            if ( link.data('confirm') ) {
                if ( ! laravel.verifyConfirm(link) ) {
                    return false;
                }
            }

            form = laravel.createForm(link);
            form.submit();

            e.preventDefault();
        },

        verifyConfirm: function(link) {
            return confirm(link.data('confirm'));
        },

        createForm: function(link) {
            let form =
                $('<form>', {
                    'method': 'POST',
                    'action': link.attr('href')
                });

            let token =
                $('<input>', {
                    'name': '_token',
                    'type': 'hidden',
                    'value': csrf.content
                });

            let hiddenInput =
                $('<input>', {
                    'name': '_method',
                    'type': 'hidden',
                    'value': link.data('method')
                });

            return form.append(token, hiddenInput)
                .appendTo('body');
        }
    };

    laravel.initialize();
});

(function () {
    /**
     * Dropdown
     */
    var dropdownElementList = [].slice.call(document.querySelectorAll('[data-toggle="dropdown"]'));
    dropdownElementList.map(function (dropdownToggleEl) {
        return new bootstrap.Dropdown(dropdownToggleEl, {})
    });

    /**
     * Tooltip
     */
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {})
    });

    /*
    Popover
     */
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="popover"]'));
    popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl, {
            autoHide: true
        })
    });

    /*
    CountUp
    */
    let countupTriggerList = [].slice.call(document.querySelectorAll("[data-countup]"));
    countupTriggerList.map(function (countupTriggerEl) {
        let dataCountUp;
        if(countupTriggerEl.getAttribute("data-countup") !== "") {
            dataCountUp = JSON.parse(countupTriggerEl.getAttribute("data-countup"));
        }

        return (new CountUp(countupTriggerEl, parseFloat(countupTriggerEl.innerText), dataCountUp)).start();
    });
})();

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo'

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: 'eu',
    forceTLS: true
});
