$(function() {
    const select = $("#js-order-state");
    const schedule = $("#js-schedule-order");
    const cancel = $("#js-order-cancel");
    const payment = $('#js-order-payment');

    if (select.val() === "scheduled") {
        schedule.show();
    }

    if (select.val() === "cancelled") {
        cancel.show();
    }

    select.change(() => {
        select.val() === "scheduled" ? schedule.show() : schedule.hide();
        select.val() === "cancelled" ? cancel.show() : cancel.hide();
        select.val() === 'billing' || select.val() === 'account' ? payment.show() : payment.hide()
    });

    if (['billing', 'account'].includes(select.val())) {
        console.log(1);
        payment.show();
    }
});
